import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../Context/AuthContext';
import { useData } from '../Context/DataContext';
import Layout from './Layout';
import Header from "../Components/Header"

const PrivateRoute = () => {
  const { currentUser } = useAuth();
  const { userGeneralId } = useData()

  const authId = sessionStorage.getItem('userId') || userGeneralId

  console.log(userGeneralId)

  return authId ? <Header /> : <Navigate to="/welcome" />
};

export default PrivateRoute;
